import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jonespen/projects/urbaninfrastructure/react-ui-kit/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Playground } from "docz";
import { Box, Container } from "@urbaninfrastructure/react-ui-kit";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "container"
    }}>{`Container`}</h1>
    <p>{`Generic wrapper component, setting max width and padding for it's children. Typically only used on top level components.`}</p>
    <p>{`Protip! Maximize the playground window on each example below to see `}<inlineCode parentName="p">{`Container`}</inlineCode>{` in a bigger screen view.`}</p>
    <h2 {...{
      "id": "default"
    }}>{`Default`}</h2>
    <Playground __position={0} __code={'<Box bg=\"primary\" color=\"white\">\n  <Container>\n    Lorem Ipsum is simply dummy text of the printing and typesetting industry.\n    Lorem Ipsum has been the industry\'s standard dummy text ever since the\n    1500s, when an unknown printer took a galley of type and scrambled it to\n    make a type specimen book.\n  </Container>\n</Box>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Box,
      Container,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Box bg="primary" color="white" mdxType="Box">
    <Container mdxType="Container">
      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
      Lorem Ipsum has been the industry's standard dummy text ever since the
      1500s, when an unknown printer took a galley of type and scrambled it to
      make a type specimen book.
    </Container>
  </Box>
    </Playground>
    <h2 {...{
      "id": "full-width"
    }}>{`Full width`}</h2>
    <p>{`Apply `}<inlineCode parentName="p">{`fullWidth`}</inlineCode>{` to make it span 90% of the page width.`}</p>
    <Playground __position={1} __code={'<Box bg=\"primary\" color=\"white\">\n  <Container fullWidth>\n    Lorem Ipsum is simply dummy text of the printing and typesetting industry.\n    Lorem Ipsum has been the industry\'s standard dummy text ever since the\n    1500s, when an unknown printer took a galley of type and scrambled it to\n    make a type specimen book.\n  </Container>\n</Box>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Box,
      Container,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Box bg="primary" color="white" mdxType="Box">
    <Container fullWidth mdxType="Container">
      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
      Lorem Ipsum has been the industry's standard dummy text ever since the
      1500s, when an unknown printer took a galley of type and scrambled it to
      make a type specimen book.
    </Container>
  </Box>
    </Playground>
    <h2 {...{
      "id": "bleed"
    }}>{`Bleed`}</h2>
    <p><inlineCode parentName="p">{`bleed`}</inlineCode>{` will remove the inner padding.`}</p>
    <Playground __position={2} __code={'<Box bg=\"primary\" color=\"white\">\n  <Container bleed>\n    Lorem Ipsum is simply dummy text of the printing and typesetting industry.\n    Lorem Ipsum has been the industry\'s standard dummy text ever since the\n    1500s, when an unknown printer took a galley of type and scrambled it to\n    make a type specimen book.\n  </Container>\n</Box>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Box,
      Container,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Box bg="primary" color="white" mdxType="Box">
    <Container bleed mdxType="Container">
      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
      Lorem Ipsum has been the industry's standard dummy text ever since the
      1500s, when an unknown printer took a galley of type and scrambled it to
      make a type specimen book.
    </Container>
  </Box>
    </Playground>
    <h2 {...{
      "id": "variants"
    }}>{`Variants`}</h2>
    <p>{`It can be either `}<inlineCode parentName="p">{`narrow`}</inlineCode>{` or `}<inlineCode parentName="p">{`wide`}</inlineCode></p>
    <Playground __position={3} __code={'<Box bg=\"primary\" color=\"white\" mb={4}>\n  <Container variant=\"narrow\">\n    Lorem Ipsum is simply dummy text of the printing and typesetting industry.\n    Lorem Ipsum has been the industry\'s standard dummy text ever since the\n    1500s, when an unknown printer took a galley of type and scrambled it to\n    make a type specimen book.\n  </Container>\n</Box>\n<Box bg=\"primary\" color=\"white\">\n  <Container variant=\"wide\">\n    Lorem Ipsum is simply dummy text of the printing and typesetting industry.\n    Lorem Ipsum has been the industry\'s standard dummy text ever since the\n    1500s, when an unknown printer took a galley of type and scrambled it to\n    make a type specimen book.\n  </Container>\n</Box>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Box,
      Container,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Box bg="primary" color="white" mb={4} mdxType="Box">
    <Container variant="narrow" mdxType="Container">
      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
      Lorem Ipsum has been the industry's standard dummy text ever since the
      1500s, when an unknown printer took a galley of type and scrambled it to
      make a type specimen book.
    </Container>
  </Box>
  <Box bg="primary" color="white" mdxType="Box">
    <Container variant="wide" mdxType="Container">
      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
      Lorem Ipsum has been the industry's standard dummy text ever since the
      1500s, when an unknown printer took a galley of type and scrambled it to
      make a type specimen book.
    </Container>
  </Box>
    </Playground>
    <h2 {...{
      "id": "component-props"
    }}>{`Component props`}</h2>
    <Props of={Container} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      